<template>
  <main class="home">
    <img class="dates" src="/lindt-dates.png" />
    <div class="central">
      <img src="/lindt-central.jpg" />
      <img class="offrez" src="/lindt-offrez.png" />
    </div>
    <div class="central2">   <img src="/mobile2.jpg" /></div>
    <img class="cook" src="/cook2.png" />
    <img class="bonbons" src="/bonbon.png" />
    <div class="participe">
      <img src="/lindt-je-participe.png" />
      <div class="action" @click="goToForm"></div>
      <div class="reglement"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/61c33dac1a4fef00169cb125/reglement.pdf`"  target="_blank">Voir le règlement</a></div>
    </div>
  </main>
</template>
<script>
var consts = require('../config');
export default {
  name: "home",
    data() {
        return {
            game_id : consts.GAME_ID,
        }
    },  
    mounted() {
      this.preloadImage('/lindt-je-participe.png')
    },
    methods: {
    preloadImage(url)
    {
        var img=new Image();
        img.src=url;
        console.log(img.src)
    },      
    goToForm() {
      this.$router.push("/form");
    }}
};
</script>