<template>
  <header>
    <div class="gold_bar"></div>
    <div class="menu">
      <ul>
        <li>
          <router-link to="/"
            ><img src="../assets/images/home.png"
          /></router-link>
        </li>
        <li><router-link to="/form">Je participe</router-link></li>
        <!-- <li><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/modalites.pdf`" target="_blank">Modalités</a></li>-->
        <li>
          <a
            rel="noopener"
            :href="
              `https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/reglement.pdf`
            "
            target="_blank"
            >Règlement</a
          >
        </li>
        <li><router-link to="/mentions">Mentions Légales</router-link></li>
        <li>
          <a
            rel="noopener"
            :href="
              `https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/politique.pdf`
            "
            targer="_blank"
            >Politique de confidentialité</a
          >
        </li>
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
    </div>
    <div class="gold_bar b-top"></div>

    <img
      @click="toggle"
      class="menu-mobile mobile"
      src="../assets/images/menu-mobile.png"
    />
    <Drawer @close="toggle" align="left" :closeable="true">
      <div v-if="open">
        <div @click="toggle">
          <router-link to="/"
            ><img class="logo-m" src="../assets/images/logo.png"
          /></router-link>
        </div>
        <ul>
          <li @click="toggle">
            <router-link to="/form">Je participe</router-link>
          </li>
          <!-- <li @click="toggle"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/modalites.pdf`" target="_blank">Modalités</a></li> -->
          <li @click="toggle">
            <a
              rel="noopener"
              :href="
                `https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/reglement.pdf`
              "
              target="_blank"
              >Règlement</a
            >
          </li>
          <li @click="toggle">
            <router-link to="/mentions">Mentions légales</router-link>
          </li>
          <li @click="toggle">
            <a
              rel="noopener"
              :href="
                `https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/politique.pdf`
              "
              targer="_blank"
              >Politique de confidentialité</a
            >
          </li>
          <li @click="toggle">
            <router-link to="/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </Drawer>
  </header>
</template>
<script>
var consts = require("../config");
import Drawer from "vue-simple-drawer";

export default {
  name: "HeaderElem",
  data() {
    return {
      game_id: consts.GAME_ID,
      open: false,
    };
  },
  components: {
    Drawer,
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
